import React from 'react';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { logoutUser } from "../../redux/user-slice";
import { auth } from '../../firebase-config';
import { useRouter } from "next/router";
import { signOut } from "firebase/auth";
import Link from "next/link";
import { Popconfirm, Tooltip } from 'antd';
import { LoginOutlined, LogoutOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import { URLS } from './url';
import Button from '../button';

const UserSessionControls: React.FC<{ closeDrawer?: () => void }> = ({ closeDrawer }) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { logined } = useAppSelector((store) => store.user);

    const handleSignOut = () => {
        signOut(auth);
        dispatch(logoutUser());
        router.push(URLS.HOME);
        if (closeDrawer) closeDrawer(); // Close drawer if in mobile mode
    };

    // Check if the current route is the login page
    const isLoginPage = router.pathname === URLS.LOGIN;

    if (!logined) {
        return <>


            <ul className='flex gap-5 items-center font-bold'>
                <li><button><Link href={URLS.LOGIN} className='text-[#1679dc]'>Login</Link></button></li>
                <li><button><Link href={URLS.REGISTER}><Button buttonType='button' className='!bg-transparent hidden md:block shadow-none border-none md:shadow-md md:bg-[#1679dc] md:text-white'>  Register</Button></Link></button></li>
            </ul>

        </>
    }

    return (
        <>
            {logined ? (
                <>
                    <Link href={URLS.FEEDBACK}>
                        <Tooltip title="Feedback">
                            <MessageOutlined className="text-xl cursor-pointer" />
                        </Tooltip>
                    </Link>
                    <Link href={URLS.USER_PROFILE}>
                        <Tooltip title="Profile">
                            <UserOutlined className="text-xl cursor-pointer" />
                        </Tooltip>
                    </Link>
                    <Popconfirm title="Logout" description="Are you sure?" okText="Yes" cancelText="No" onConfirm={handleSignOut}>
                        <LogoutOutlined className="text-xl cursor-pointer" />
                    </Popconfirm>
                </>
            ) : (
                // Only show the login button if the current page is not the login page
                !isLoginPage && router.pathname !== '/' && (
                    <Link href={URLS.LOGIN}>
                        <LoginOutlined />
                    </Link>
                )
            )}
        </>
    );
};

export default UserSessionControls;