// hooks/useFirebaseAuth.js
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import app from '../../firebase-config';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { logoutUser } from '../../redux/user-slice';

const useFirebaseAuth = () => {
    const userInfo = useAppSelector((state) => state.user.userInfo);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const accessToken = await user.getIdToken();
                // dispatch(setLogined({
                //     displayName: user.displayName,
                //     email: user.email,
                //     photoURL: user.photoURL,
                //     uid: user.uid,
                //     accessToken,
                // }));
            } else {
                dispatch(logoutUser());
            }
        });

        // Periodically refresh the token
        const refreshTokenInterval = setInterval(async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const accessToken = await currentUser.getIdToken(true); // Force refresh token
                // dispatch(setLogined({
                //     displayName: currentUser.displayName,
                //     email: currentUser.email,
                //     photoURL: currentUser.photoURL,
                //     uid: currentUser.uid,
                //     accessToken,
                // }));
            }
        }, 30 * 60 * 1000); // Refresh token every 30 minutes

        return () => {
            unsubscribe();
            clearInterval(refreshTokenInterval); // Clear the interval on component unmount
        };
    }, []);

    return userInfo;
};

export default useFirebaseAuth;
