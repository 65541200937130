import { motion } from "framer-motion";
import { ReactNode } from "react";

interface ButtonProps {
  onClick?: () => void;
  children: ReactNode;
  buttonType?: "button" | "reset" | "submit";
  disabled?: boolean;
  className?: string;
  variant?: "submit" | "secondary";
  useMotion?: boolean; // Optional prop to toggle motion effect
}

export default function Button({
  onClick,
  children,
  buttonType = 'button',
  className,
  disabled = false,
  variant = "submit",
  useMotion = true,
}: ButtonProps) {
  const baseStyles = `p-2 min-w-32 md:min-w-44 text-md shadow-md max-w-[400px] w-full rounded-md flex justify-center font-medium items-center gap-2 ${
    disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
  } ${className}`;
  
  const variantStyles =
    variant === "submit"
      ? "bg-cyan-500 text-white hover:bg-cyan-600"
      : "bg-[#3b82f6] text-white";

  const ButtonComponent = useMotion ? motion.button : "button";

  return (
    <ButtonComponent
      disabled={disabled}
      whileHover={useMotion ? { scale: 1 } : undefined}
      whileTap={useMotion ? { scale: 0.95 } : undefined}
      className={`${baseStyles} ${variantStyles}`}
      onClick={onClick}
      type={buttonType}
    >
      {children}
    </ButtonComponent>
  );
}