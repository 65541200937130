import React from 'react';
import Link from 'next/link';

interface NavLinkProps {
    href?: string;
    label: string;
    className?: string;
    onClick?: () => void
}

const NavLink: React.FC<NavLinkProps> = ({ href, onClick, label, className = '' }) => {
    return (
        <Link href={href} onClick={onClick}>
            <span className={`hover:text-blue-500 cursor-pointer ${className}`}>{label}</span>
        </Link>
    );
};

export default NavLink;