import React from 'react';
import NavLink from './nav-link';
import { URLS } from './url';
import { CoverLetterDropdown, ResumeDropdown } from './menu-dropdown';
import useFirebaseAuth from '../hooks/useAuth';

const DesktopMenu: React.FC = () => {

    const userInfo = useFirebaseAuth();

    return (
        <div className="hidden font- lg:flex text-lg space-x-8 items-center">
            {
                !userInfo && <>
                    <ResumeDropdown />
                    <CoverLetterDropdown />
                    <NavLink href={URLS.ATS_RESUME_SCORE} label="Resume Score" />
                </>
            }
            {
                userInfo && <>

                    <NavLink href={URLS.RESUME_SCORE_SCAN} label="Resume Score" />
                    <NavLink href={URLS.COVER_LETTER} label="Cover Letter" />
                    <NavLink href={URLS.JD_TO_CV} label="JD To CV" />
                </>
            }

            
        </div>
    );
};

export default DesktopMenu;