// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD67AHPxyxfNQeDSEsmqXT_3QEsuowYIgQ",
    authDomain: "auth.neuracv.com",
    projectId: "neuracv-52686",
    storageBucket: "neuracv-52686.appspot.com",
    messagingSenderId: "403765828467",
    appId: "1:403765828467:web:b47d595fe2afedd9fac306",
    measurementId: "G-K2HMQWJFHW"
};
// Initialize Firebase
let app;
if (!getApps().length) {
    app = initializeApp(firebaseConfig);
} else {
    app = getApp(); // if already initialized, use that one
}

export default app;
export const auth = getAuth(app);

const analytics = typeof window !== 'undefined' ? getAnalytics(app) : null;
export { analytics };