import React from 'react';
import { Dropdown, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { URLS } from './url';
import NavLink from './nav-link';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { useGetUserResumesMutation } from '../../redux/queries/templates-query';
import { useCreateNewDocumentMutation, useIsDocumentCreationAllowedMutation } from '../../redux/queries/user-query';
import { defaultUserFormData } from '../../redux/data/form-data';
import { camelCaseToTitle } from '../../utils/helper';
import { selectDesignerResume } from '../../redux/designer';
import Router from 'next/router';
import { DEFAULT_FONT } from '../../utils/fonts';

// MenuDropdown Component
const MenuDropdown: React.FC = () => {
    const menuItems = [
        {
            key: '1',
            label: <NavLink href={URLS.ATS_RESUME_SCORE} label="Resume Score" />
        }
    ];

    return (
        <Dropdown
            menu={{
                items: menuItems,
            }}
            trigger={['hover']}
        >
            <span className="ant-dropdown-link flex items-center cursor-pointer">
                ATS <DownOutlined className="ml-1" />
            </span>
        </Dropdown>
    );
};

// CoverLetterDropdown Component
export const CoverLetterDropdown: React.FC = () => {
    const userInfo = useAppSelector((state) => state.user.userInfo);

    const menuItems = [
        {
            key: '1',
            label: <NavLink href={URLS.HOW_TO_WRITE_COVER_LETTER} label="How to Write a Cover Letter" />,
        },
        {
            key: '2',
            label: <NavLink href={URLS.CHATGPT_COVER_LETTER} label="ChatGPT Cover Letter" />,
        },
        {
            key: '3',
            label: <NavLink href={URLS.RESUME_COVER_LETTER_DIFFERENCE} label="Resume & Cover Letter Difference" />,
        },
        {
            key: '4',
            label: (
                <div className="px-4 py-2 mt-2 bg-[#0277c8] text-white rounded-md text-center cursor-pointer hover:bg-[#0277c8]">
                    <NavLink href={userInfo ? URLS.COVER_LETTER : URLS.LOGIN} label="Create Cover Letter" />
                </div>
            ),
        },
    ];

    return (
        <Dropdown
            menu={{
                items: menuItems,
            }}
            trigger={['hover']}
        >
            <span className="ant-dropdown-link flex items-center cursor-pointer">
                Cover Letter
            </span>
        </Dropdown>
    );
};

// ResumeDropdown Component
export const ResumeDropdown: React.FC = () => {
    const dispatch = useAppDispatch();
    const { appTemplates } = useAppSelector((state: RootState) => state.template);
    const userInfo = useAppSelector((state) => state.user.userInfo);
    const [checkDocumentCreationLimit] = useIsDocumentCreationAllowedMutation();
    const [getUserResumes] = useGetUserResumesMutation();
    const [createNewDocument] = useCreateNewDocumentMutation();

    const onCreateNewResume = async () => {
        const defaultTemplate = appTemplates.find((o) => o.category === 'resume');

        if (defaultTemplate) {
            try {
                const data = await createNewDocument({
                    templateConfig: {
                        selectedTemplateId: defaultTemplate.id,
                        selectedColorSetId: defaultTemplate.defaultColorSetId,
                        selectedFont: DEFAULT_FONT
                    },
                    title: 'Untitled',
                    formData: {
                        ...defaultUserFormData,
                        basicInfo: {
                            ...defaultUserFormData.basicInfo,
                            firstName: '',
                            coverLetterFromName: '',
                            email: userInfo?.email,
                            professionalSummary: '',
                            linkedin: '',
                        },
                    },
                    document_type: "resume"
                }).unwrap();

                if (data && data.success && data.documentId) {
                    await getUserResumes().unwrap();
                    dispatch(selectDesignerResume(data.documentId));
                    Router.push("/design");
                } else {
                    message.warning(data.message);
                }
            } catch (e) {
                const data: any = await checkDocumentCreationLimit().unwrap();
                if (!data.isAllowed) {
                    message.warning(data.message);
                }
            }
        }
    };

    const menuItems = [
        {
            key: '1',
            label: (
                <>
                    <div className="p-4  rounded-md mb-2">
                        <h3 className="text-lg font-bold mb-1">Resume Builder</h3>
                        <p className="text-sm">Build Your ATS Resumes with our AI-powered designer.</p>
                    </div>
                </>
            ),
        },
        {
            key: '2',
            label: <NavLink href={URLS.HOW_TO_OPTMIZE_YOUR_RESUME} label="How to Optimize Your Resume" />,
        },
        {
            key: '3',
            label: <NavLink href={URLS.HOW_TO_WRITE_ATS_RESUME} label="How To Write ATS resume" />,
        },
        
        {
            key: '4',
            label: <NavLink href={URLS.TOP_RESUME_MISTAKES} label="Top Resume Mistakes" />,
        },
        {
            key: '5',
            label: <NavLink href={URLS.RESUME_TEMPLATE_CATEGORIES} label="Profesional Resume Contents" />,
        },
        {
            key: '6',
            label: (
                <div className="px-4 py-2 mt-2 bg-[#0277c8] text-white rounded-md text-center cursor-pointer hover:bg-[#0277c8]">
                    <NavLink href={userInfo ? URLS.DASHBOARD : URLS.LOGIN} onClick={onCreateNewResume} label="Create Resume" />
                </div>
            ),
        },
    ];

    return (
        <Dropdown
            menu={{
                items: menuItems,
            }}
            trigger={['hover']}
        >
            <span className="ant-dropdown-link flex items-center cursor-pointer">
                Resume
            </span>
        </Dropdown>
    );
};

export default MenuDropdown;