import React from 'react';
import Link from 'next/link';
import { URLS } from './url';
import useFirebaseAuth from '../hooks/useAuth';

const Logo: React.FC = () => {

    const userInfo = useFirebaseAuth();

    return (
        <div className="text-lg font-medium">
            <Link href={userInfo ? URLS.DASHBOARD : URLS.HOME} aria-label="Neura Home Page">
                <label className='flex gap-2 items-center font-bold text-sm text-gray-800'>
                    <img alt="Neura CV Logo" className='w-32' src='/images/svgs/logo.svg'  />
                </label>
            </Link>
        </div>
    );
};
 
export default Logo;