import { UserFormData } from "../../store/models/formData";

export const defaultUserFormData: UserFormData = {
    basicInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        professionalSummary: '',
        professionalTitle: '',
        email: '',
        name: '',
        linkedin: '',
        github: '',
        address: '',
        coverLetterFromName: '',
    },
    course: [{
        name: ''
    }],
    educationList: [{
        degree: '',
        id: 0,
        school: ''
    }],
    hobbies: [{
        id: 0,
        name: ''
    }],
    internship: [{
        employer: '',
        id: 0,
        title: '',
    }],
    languages: [{
        id: 0,
        name: '',
        value: 1
    }],
    projects: [{
        id: 1,
        name: '',
        description: '',
        teckStacks: ''
    }],
    skills: [{
        id: '',
        name: '',
        value: 1
    }],
    achievements: [{
        id: '',
        name: '',
        value: 1
    }],
    workHistory: [{
        employer: '',
        id: 0,
        title: '',
    }]
}