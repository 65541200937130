// src/components/MobileDrawer.tsx
import React from 'react';
import { Drawer, Menu } from 'antd';
import { CloseOutlined, LogoutOutlined } from "@ant-design/icons";
import MenuDropdown, { CoverLetterDropdown, ResumeDropdown } from './menu-dropdown';
import NavLink from './nav-link';
import { URLS } from './url';
import UserSessionControls from './user-session-controls';
import useFirebaseAuth from '../hooks/useAuth';
import { useAppDispatch } from '../../redux/store';
import { useRouter } from 'next/router';
import { signOut } from 'firebase/auth';
import { logoutUser } from '../../redux/user-slice';
import { auth } from '../../firebase-config';
import Button from '../button';

interface MobileDrawerProps {
    drawerVisible: boolean;
    closeDrawer: () => void;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ drawerVisible, closeDrawer }) => {

    const userInfo = useFirebaseAuth()
    const dispatch = useAppDispatch();
    const router = useRouter();

    const handleSignOut = () => {
        signOut(auth);
        dispatch(logoutUser());
        router.push(URLS.HOME);
        if (closeDrawer) closeDrawer(); // Close drawer if in mobile mode
    };

    return (
        <Drawer
            title={process.env.NEXT_PUBLIC_APP_TITLE}
            placement="right"
            onClose={closeDrawer}
            open={drawerVisible}
            width={300}
            closeIcon={<CloseOutlined />}
        >




            <Menu mode="vertical" className='shadow-none border-none p-0 m-0'>


                {
                    !userInfo && <>
                        <Menu.Item key="1"><ResumeDropdown /></Menu.Item>
                        <Menu.Item key="2"><NavLink href={URLS.ATS_RESUME_SCORE} label="Resume Score" /></Menu.Item>
                        <Menu.Item key="3"><CoverLetterDropdown /></Menu.Item>
                    </>
                }
                {
                    userInfo && <>

                        <Menu.Item>
                            <NavLink href={URLS.RESUME_SCORE_SCAN} label="Resume Score" />
                        </Menu.Item>

                        <Menu.Item>
                            <NavLink href={URLS.COVER_LETTER} label="Cover Letter" />
                        </Menu.Item>

                        <Menu.Item>
                            <NavLink href={URLS.USER_PROFILE} label="User Profile" />
                        </Menu.Item>

                        <Menu.Item>
                            <NavLink href={URLS.FEEDBACK} label="Feedback" />
                        </Menu.Item>

                        <Menu.Item>
                            <NavLink href={URLS.FEEDBACK} label="Feedback" />
                        </Menu.Item>



                    </>
                }


                {/* 
                <Menu.Item key="1">
                    <MenuDropdown />
                </Menu.Item>
                <Menu.Item key="2">
                    <NavLink href={URLS.ATS_RESUME} label="ATS Resume" />
                </Menu.Item>
                <Menu.Item key="3">
                    <NavLink href={URLS.COVER_LETTER} label="Cover Letter" />
                </Menu.Item>
                <Menu.Item key="4">
                    <NavLink href={URLS.LINKEDIN} label="LinkedIn" />
                </Menu.Item>
                <Menu.Item key="5">
                    <NavLink href={URLS.PRICING} label="Pricing" />
                </Menu.Item>
            
             */}
            </Menu>
            <Button onClick={handleSignOut} className={`hover:text-blue-500 cursor-pointer`}>
                <LogoutOutlined /> Logout
            </Button>

            {/* <div className="mt-6 flex gap-4 justify-center">
                <UserSessionControls closeDrawer={closeDrawer} />
            </div> */}
        </Drawer>
    );
};

export default MobileDrawer;