// fonts.js
import { Roboto, Lato, Poppins, Open_Sans, Tinos, Carlito, Ubuntu } from 'next/font/google';

export const roboto = Roboto({
    subsets: ['latin'],
    weight: ['400', '700', '300', '100', '900', '500'],
});

export const poppins = Poppins({
    subsets: ['latin'],
    weight: ['400', '700', '300', '100', '900', '500'],
});

export const lato = Lato({
    subsets: ['latin'],
    weight: ['400', '700', '100', '300', '900'],
});


export const openSans = Open_Sans({
    subsets: ['latin'],
    weight: ['300', '400', '500', '600', '700', '800']
});

export const tinos = Tinos({
    subsets: ['latin'],
    weight: ['400', '700']
});

export const carlito = Carlito({
    subsets: ['latin'],
    weight: ['400', '700']
});

export const ubuntu = Ubuntu({
    subsets: ['latin'],
    weight: ['300', '400', '500', '700']
});

export const NEXT_FONT_OBJECT = {
    "roboto": roboto.className,
    "poppins": poppins.className,
    "lato": lato.className,
    "open sans": openSans.className,
    "tinos": tinos.className,
    "carlito": carlito.className,
    "ubuntu": ubuntu.className
}

export const AVAILABLE_FONTS = ['open sans', 'poppins', 'roboto', 'ubuntu']
export const DEFAULT_FONT = 'open sans';
export const DEAFULT_FONT_SIZE = "text-base";